import React from 'react';
import './style.scss';

class Links extends React.Component {
  render() {
    const author = this.props.data;
    const links = {
      // telegram: author.telegram,
      // twitter: author.twitter,
      // github: author.github,
      rss: author.rss,
      email: author.email,
      // ivoox: author.ivoox,
      itunes: author.itunes,
      spotify: author.spotify,
      google: author.google,
      facebook: author.facebook,
      instagram: author.instagram
    };
    // console.log(links);

    return (
      <div className="links">
        <ul className="links__list">
          <li className="links__list-item">
            <a href={`mailto:${links.email}`}>
              <i className="icon-mail" />
            </a>
          </li>
          {/* <li className="links__list-item">
            <a href={`telegram:${links.telegram}`}>
              <i className="icon-paper-plane" />
            </a>
          </li> */}
        </ul>
        <ul className="links__list">
          <li className="links__list-item">
            <a href={links.facebook} rel="noreferrer" target="_blank">
              <i className="icon-facebook" />
            </a>
          </li>
          <li className="links__list-item">
            <a href={links.instagram} target="_blank" rel="noreferrer">
              <i className="icon-instagram" />
            </a>
          </li>
          {/* <li className="links__list-item">
            <a href={`https://www.vk.com/${links.vk}`} target="_blank" >
              <i className="icon-vkontakte" />
            </a>
          </li> */}
        </ul>
        <ul className="links__list">
          <li className="links__list-item">
            <a href={links.rss}>
              <i className="icon-rss" />
            </a>
          </li>
        </ul>
        <ul className="links__list">
          <li className="links__list-item">
            <a href={links.spotify} target="_blank" rel="noreferrer">
              <i className="icon-spotify" />
            </a>
          </li>
          <li className="links__list-item">
            <a href={links.itunes} target="_blank" rel="noreferrer">
              <i className="icon-podcast" />
            </a>
          </li>
          <li className="links__list-item">
            <a href={links.google} target="_blank" rel="noreferrer">
              <i className="icon-google" />
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Links;
